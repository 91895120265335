import { createI18n } from 'vue-i18n'
import { router } from '../views'
import en from '../i18n/en.json'
import { RouteRecordRaw } from 'vue-router'
import { ShallowRef, shallowRef, WritableComputedRef } from '@vue/reactivity'
import FlagUS from '../assets/icons/flags/us.svg'
import FlagRU from '../assets/icons/flags/ru.svg'
import FlagPT from '../assets/icons/flags/pt.svg'
import FlagDE from '../assets/icons/flags/de.svg'
import FlagES from '../assets/icons/flags/es.svg'
import FlagFR from '../assets/icons/flags/fr.svg'
import FlagPL from '../assets/icons/flags/pl.svg'

export const i18n = createI18n({
	pluralRules: {
		ru: (choice, choicesLength) => {
			if (choice === 0) {
				return 0
			}

			const teen = choice > 10 && choice < 20
			const endsWithOne = choice % 10 === 1
			if (!teen && endsWithOne) {
				return 1
			}
			if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
				return 2
			}

			return choicesLength < 4 ? 2 : 3
		}
	},
	legacy: false,
	locale: 'en',
	fallbackLocale: 'en',
	messages: { en },
	silentFallbackWarn: true
})

export type LangCode = 'en' | 'ru' | 'pt' | 'de' | 'es' | 'fr' | 'pl'

interface Lang {
	code: LangCode
	name: string
	icon: ShallowRef<string> | string
}

export function languages(): Lang[] {
	return [
		{
			code: 'en',
			name: 'English',
			icon: shallowRef(FlagUS)
		},
		{
			code: 'ru',
			name: 'Русский',
			icon: shallowRef(FlagRU)
		},
		{
			code: 'pt',
			name: 'Português',
			icon: shallowRef(FlagPT)
		},
		{
			code: 'de',
			name: 'Deutsch',
			icon: shallowRef(FlagDE)
		},
		{
			code: 'es',
			name: 'Español',
			icon: shallowRef(FlagES)
		},
		{
			code: 'fr',
			name: 'Français',
			icon: shallowRef(FlagFR)
		},
		{
			code: 'pl',
			name: 'Polski',
			icon: shallowRef(FlagPL)
		}
	]
}
const loadedLanguages = ['en']

function saveLanguage(lang: string) {
	localStorage.setItem('lang', lang)
}

let loading = false
function setLoading(value: boolean) {
	loading = value

	if (!value && queue.length) {
		const [lang] = queue
		queue.splice(0, 1)
		useLocaleAsync(lang)
	}
}

function setI18nLanguage(lang: string) {
	;(i18n.global.locale as WritableComputedRef<string>).value = lang
	saveLanguage((i18n.global.locale as WritableComputedRef<string>).value)
	setLoading(false)
	return lang
}

function getBrowserLang() {
	let langs = ''
	if (navigator.languages && navigator.languages.length) {
		langs = navigator.languages.toString().toLowerCase()
	} else if (navigator.language) {
		langs = navigator.language.toLowerCase()
	}

	const languagesLocal = languages()
	for (let i = 0; i < languagesLocal.length; i++) {
		const { code } = languagesLocal[i]
		if (langs.includes(code)) {
			return code
		}
	}

	if (langs.includes('en') || langs.includes('us')) {
		return 'en'
	}

	return null
}

export function initLang() {
	const lang = localStorage.getItem('lang')
	const browserLang = getBrowserLang()

	if (!lang && browserLang) {
		return browserLang
	}

	if (!lang) {
		return 'en'
	}

	if (!languages().find((language) => language.code === lang)) {
		localStorage.removeItem('lang')
		return 'en'
	}

	return lang
}

const queue: (string | null | undefined)[] = []

export async function useLocaleAsync(lang?: string | null) {
	if (loading) {
		return queue.push(lang)
	}

	if (!lang) {
		lang = initLang()
	} else {
		saveLanguage(lang)
	}

	if ((i18n.global.locale as WritableComputedRef<string>).value === lang) {
		return Promise.resolve(setI18nLanguage(lang))
	}
	if (loadedLanguages.includes(lang as string)) {
		return Promise.resolve(setI18nLanguage(lang as string))
	}

	setLoading(true)

	return import(`../i18n/${lang}.json`)
		.then((messages) => {
			i18n.global.setLocaleMessage(lang as string, messages.default)
			loadedLanguages.push(lang as string)
			setI18nLanguage(lang as string)
			return
		})
		.catch(() => {
			setLoading(false)
		})
}

export function generateLangRoutes(): RouteRecordRaw[] {
	return languages().map(({ code }) => {
		return {
			path: `/${code}`,
			name: code,
			redirect: '/',
			beforeEnter(_, __, next) {
				useLocaleAsync(code)
				next('/')
			}
		}
	})
}

router.afterEach(() => {
	useLocaleAsync()
})
